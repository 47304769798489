<template>
  <div>
    <validation-observer ref="simple">
      <b-form>
        <b-card-code title="إضافة دليل حساب">
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-row>
              <b-col
                md="3"
                xl="3"
              >

                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="title"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label> الاسم</label>
                    <b-form-input
                      id="input-title"
                      v-model="name"
                      type="text"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="code"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label> الكود</label>
                    <b-form-input
                      id="input-default"
                      v-model="code"
                      type="text"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="employe"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label> حساب رئيسي / فرعي</label>
                    <v-select
                      v-model="account"
                      label="name"
                      :options="optionsAccount"
                      :reduce="(val) => val.id"
                      @input="getAccounts(account)"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                v-if="(account==1)"
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="employe"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label> الحساب </label>
                    <v-select
                      v-model="parent_account_id"
                      label="name"
                      :options="optionsAllAccount"

                      :reduce="(val) => val.id"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="12"
                xl="12"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="employe"
                    :state="errors.length > 0 ? false : null"
                  >

                    <b-form-checkbox
                      v-model="account_type"
                      plain
                      class="custom-control-warning"
                      value="0"
                    >
                      <label>  مدين</label>
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="12"
                xl="12"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="employe"
                    :state="errors.length > 0 ? false : null"
                  >

                    <b-form-checkbox
                      v-model="account_type"
                      plain
                      class="custom-control-warning"
                      value="1"
                    >
                      <label>  دائن</label>
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="1"
                xl="3"
              >
                <b-button
                v-if="!loading"
                  variant="purple"
                  @click="addWarehouse"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">  إضافة  </span>
                </b-button>
                <b-button
                v-else
                  variant="purple"
                 disabled
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">  جار الاضافة  </span>
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card-code>
      </b-form>
    </validation-observer>
    <b-card-code title=" ">
      <!-- search input -->

      <div id="jstree-basic">
        <b-row>
          <b-col
            md="6"
            xl="6"
          >
            <!-- <ul
              v-for="(item,index) in rows"
              :key="index"
              role="button"
              @click="child=item.children"
            > -->

              <!-- <label> الاسم</label>
              <li data-jstree="{&quot;icon&quot; : &quot;ti ti-folder&quot;}">
                {{ item.name }}
              </li>
              <label> الكود</label>
              <li data-jstree="{&quot;icon&quot; : &quot;ti ti-folder&quot;}">
                {{ item.code }}
              </li>
              <label> الرصيد</label>
              <li data-jstree="{&quot;icon&quot; : &quot;ti ti-folder&quot;}">
                {{ item.balance }}
              </li>
              <label> نوع الحساب</label> -->
              <!-- <li
                v-if="item.account_type==1"
                data-jstree="{&quot;icon&quot; : &quot;ti ti-folder&quot;}"
              >
                دائن
              </li>
              <li
                v-else
                data-jstree="{&quot;icon&quot; : &quot;ti ti-folder&quot;}"
              >
                مدين
              </li>

              <li>
              <label>  الحساب</label> -->
   
              <v-jstree
                :data="rows"
                
                opened
                @item-click="showChildren"
              >
              
              </v-jstree>
             
              </li>
              <!-- <li
                v-if="item.parent_account_id==null"
                data-jstree="{&quot;icon&quot; : &quot;ti ti-folder&quot;}"
                role="button"
                @click="child=item.childs"
              >
                حساب اساسي
              </li>
              <li
                v-else
                data-jstree="{&quot;icon&quot; : &quot;ti ti-folder&quot;}"
                role="button"
                @click="child=item.childs"
              >
                حساب فرعي
                <ul>
                  <li data-jstree="{&quot;icon&quot; : &quot;ti ti-folder&quot;}">
                    {{ item.parent_account_id.name }}
                  </li>

                </ul>
              </li> -->
              <!-- <hr></hr>
            </ul> -->
          </b-col>
          <b-col
            v-if="child"
            md="6"
            xl="6"
          >

            <b-row
            
            >
             
              <b-col
                md="3"
                xl="3"
                v-if="child.code"
              >

                <b-form-group
                  label-for="title"
                >

                  <label> الكود</label>
                  <b-form-input
                    id="input-title"
                    v-model="child.code"
                    type="text"
                    disabled
                  />
                </b-form-group>

              </b-col>
              <b-col
                md="3"
                xl="3"
                v-if="child.balance"
              >

                <b-form-group
                  label-for="title"
                >

                  <label> الرصيد</label>
                  <b-form-input
                    id="input-title"
                    v-model="child.balance"
                    type="text"
                    disabled
                  />
                </b-form-group>

              </b-col>
          
              <b-col
              md="3"
                xl="3"
                v-if="child.account_type"
              >

                <b-form-group
                  label-for="title"
                >

                  <label> النوع</label>
                  <p v-if="child.account_type=0">
                    مدين
                  </p>
                  <p v-else>
                    دائن
                  </p>
                </b-form-group>

              </b-col>
             
            </b-row>
          </b-col>
        </b-row>
      </div>
    </b-card-code>
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import { required, max, is_earlier } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'

import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,

  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VJstree from 'vue-jstree'

export default {

  components: {
    VJstree,
    ToastificationContent,
    required,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
    BForm,
    ValidationObserver,
    ValidationProvider,

    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,

    BCol,
  },

  data() {
    return {
      name: '',
      loading: false,
      number: '',
      account: '',
      data: [
        {
          text: 'Same but with checkboxes',
          children: [
            {
              text: 'initially selected',
              
            },
            {
              text: 'custom icon',
              icon: 'fa fa-warning icon-state-danger',
            },
            {
              text: 'initially open',
              icon: 'fa fa-folder icon-state-default',
              
              children: [
                {
                  text: 'Another node',
                },
              ],
            },
            {
              text: 'custom icon',
              icon: 'fa fa-warning icon-state-warning',
            },
            {
              text: 'disabled node',
              icon: 'fa fa-check icon-state-success',
              disabled: true,
            },
          ],
        },
        {
          text: 'Same but with checkboxes',
          
          children: [
            {
              text: 'initially selected',
              
            },
            {
              text: 'custom icon',
              icon: 'fa fa-warning icon-state-danger',
            },
            {
              text: 'initially open',
              icon: 'fa fa-folder icon-state-default',
              
              children: [
                {
                  text: 'Another node',
                },
              ],
            },
            {
              text: 'custom icon',
              icon: 'fa fa-warning icon-state-warning',
            },
            {
              text: 'disabled node',
              icon: 'fa fa-check icon-state-success',
              disabled: true,
            },
          ],
        },
        {
          text: 'And wholerow selection',
        },
      ],
      optionsWareHouse: [{
        id: 0,
        name: 'مدين',
      },
      {
        id: 1,
        name: 'دائن',
      },

      ],
      child: {},
      optionsAllAccount: [],
      optionsAccount: [{
        id: 0,
        name: 'رئيسي',
      },
      {
        id: 1,
        name: 'فرعي',
      },

      ],

      parent_account_id: null,
      code: '',
      en_name: '',
      account_type: '',

      columns: [
        {
          label: ' الاسم',
          field: 'name',
        },

        {
          label: ' الموقع',
          field: 'code',
        },

        {
          label: 'الخيارات',
          field: 'id',
          sortable: true,
        },
      ],
      rows: [],
      searchTerm: '',
      id: '',

    }
  },
  computed: {
    dataTree() {
      const data=this.rows

      return data
    },
  },

  created() {
    this.getWarehouse()
  },

  methods: {
    getAccounts(id) {
      if (id == 1) {
        this.optionsAllAccount = []
        const url = '/api/v1/finance/get-all-accounts-list'
        this.$http.get(url).then(res => {
          //console.log(res)

          this.optionsAllAccount = res.data.data
        })
      } else {
        this.parent_account_id = null
      }
    },
    showChildren(node, item, e){
//console.log("e",item)
this.child=item
    },
    deleteWarehouse(id) {
      this.$swal({
        icon: 'warning',
        title: 'هل انت متأكد من الحذف',
        confirmButtonText: 'موافق',
        cancelButtonText: 'الغاء',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'لا',
        },
      }).then(result => {
        if (result.value) {
          const url = `/api/v1/warehouses/${id}`
          this.$http.delete(url).then(res => {
            //console.log(res)
            this.getWarehouse()
            this.$swal({
              icon: 'warning',
              title: 'تم الحذف  بنجاح',
              confirmButtonText: 'موافق',
              cancelButtonText: 'الغاء',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'لا',
              },
            })
          })
        }
      })
    },
    addWarehouse() {
      return new Promise((resolve, reject) => {
        this.$refs.simple
          .validate()
          .then(success => {
            if (success) {
              let data = []
              this.loading = true
              if (this.account == 1) {
                data = {

                  name: this.name,
                  code: this.code,
                  kind: this.account,
                  account_type: this.account_type,
                  parent_account_id: this.parent_account_id,
                }
              } else {
                data = {
                  account_type: this.account_type,
                  name: this.name,
                  kind: this.account,
                  code: this.code,
                  parent_account_id: null,
                }
              }

              this.$http.post('/api/v1/finance/accounts', data).then(res => {
                //console.log(res)
                this.name = ''
                this.loading = false
                this.number = ''
                this.code = ''
                this.account=''
                this.parent_account_id = ''
               
                this.account_type = ''
                this.getWarehouse()
                requestAnimationFrame(() => {
                  this.$refs.simple.reset()
                })
                this.$swal({
                  title: '',
                  text: 'تمت أضافة  بنجاح',
                  icon: 'success',
                  confirmButtonText: 'موافق',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
            }
          })
      })
    },

    getWarehouse() {
      this.$http.get('/api/v1/finance/accounts').then(res => {
        //console.log('rewards', res)
        this.rows = res.data.data
       
        // let i
        // for (i = 0; i < this.rows.length; i++) {
        //   //  this.rows[i].children =  this.rows[i]['childs'];
        //   // delete  this.rows[i].childs;
        //   this.rows[i].state = 'open'
         
        // }
      })
    },

    //
  },
}
</script>

<style scoped>
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}

span {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
ul {
    width: 300px;
}
.align-left {
  left: 50px;
}
.but {
  top: 40px;
}
ul.tree-container-ul.tree-children.tree-wholerow-ul {
    text-align: initial;
}
</style>
